import '@reach/accordion/styles.css';

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  useAccordionItemContext,
} from '@reach/accordion';
import SanityBlockContent from '@sanity/block-content-to-react';
import { AnimatePresence, motion } from 'framer-motion';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import * as React from 'react';

import {
  ContactForm,
  CopyWithFullHeightImage,
  CopyWithImages,
  Layout,
  SEO,
} from '../components';
import { CallToAction } from '../components/call-to-action';

function HabitatPage({ data }) {
  return (
    <Layout
      heroImage={data.sanityHabitatPage.heroImage.asset.fluid}
      heroAltText={data.sanityHabitatPage.heroImage.altText}
    >
      <SEO title="Habitat" />
      <KoalaHabitat data={data.sanityHabitatPage.koalaHabitat} />
      <Species
        primary={data.sanityHabitatPage.primarySpecies}
        secondary={data.sanityHabitatPage.secondarySpecies}
      />
      <FAQs data={data.sanityHabitatPage.faq} />
      <ContactForm />
    </Layout>
  );
}

HabitatPage.propTypes = {
  data: PropTypes.object.isRequired,
};

function KoalaHabitat({ data }) {
  return (
    <CopyWithImages images={data.images}>
      <SanityBlockContent blocks={data._rawText} className="prose" />
      {data.callToAction ? (
        <CallToAction callToAction={data.callToAction} />
      ) : null}
    </CopyWithImages>
  );
}

KoalaHabitat.propTypes = {
  data: PropTypes.object.isRequired,
};

function Tree({ tree, lastElement }) {
  return (
    <div className="pt-6 sm:py-0 sm:divide-x sm:divide-white sm:divide-opacity-50 sm:grid sm:grid-cols-3 sm:gap-x-4">
      <dt
        className={`text-sm font-medium sm:pt-4 sm:px-6 ${
          lastElement ? 'sm:pb-4' : 'sm:pb-0'
        }`}
      >
        {tree.key}
      </dt>
      <dd
        className={`mt-1 text-sm sm:mt-0 sm:px-6 sm:pt-4 sm:col-span-2 ${
          lastElement ? 'sm:pb-4' : 'sm:pb-0'
        }`}
      >
        {tree.value}
      </dd>
    </div>
  );
}

Tree.propTypes = {
  lastElement: PropTypes.bool.isRequired,
  tree: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
};

const transition = { min: 0, max: 100, bounceDamping: 9 };

function Trees({ heading, trees }) {
  const { isExpanded } = useAccordionItemContext();
  return (
    <>
      <h3 className="px-4 py-5 sm:px-6">
        <AccordionButton className="relative inline-flex items-center text-lg font-bold tracking-wider uppercase">
          <span className="absolute -left-5">
            <motion.svg
              initial={{ rotate: '0deg' }}
              animate={{ rotate: isExpanded ? '0deg' : '90deg' }}
              transition={transition}
              stroke="currentColor"
              fill="currentColor"
              strokeWidth={0}
              viewBox="0 0 20 20"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </motion.svg>
          </span>
          <span>{heading}</span>
        </AccordionButton>
      </h3>
      <AccordionPanel>
        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial="closed"
              animate="open"
              exit="closed"
              variants={{
                open: { height: 'auto', opacity: 1, overflow: 'visible' },
                closed: { height: 0, opacity: 0, overflow: 'hidden' },
              }}
              transition={transition}
              className="px-4 py-5 sm:p-0"
            >
              <dl className="px-4 pb-6 border border-white border-opacity-50 sm:pb-0 sm:px-0">
                {trees.map((tree, index) => (
                  <Tree
                    key={tree._key}
                    tree={tree}
                    lastElement={index === trees.length - 1}
                  />
                ))}
              </dl>
            </motion.div>
          )}
        </AnimatePresence>
      </AccordionPanel>
    </>
  );
}

Trees.propTypes = {
  heading: PropTypes.string,
  trees: PropTypes.arrayOf(
    PropTypes.shape({
      commonName: PropTypes.string,
      scientificName: PropTypes.string,
    })
  ),
};

const keys = [
  {
    dt: 'N',
    dd: 'At Northern extremity of distribution',
  },
  {
    dt: 'T',
    dd: 'Threatened Species Listed',
  },
  {
    dt: 'S',
    dd: 'At Southern extremity of distribution',
  },
  {
    dt: 'L',
    dd: 'Very limited distribution',
  },
];

function Species({ primary, secondary }) {
  return (
    <article className="mx-auto text-white bg-green max-w-7xl">
      <div className="max-w-4xl py-16 mx-auto overflow-hidden">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-3xl font-bold text-center">
            Locally known Primary and Secondary Browse Species
          </h2>
        </div>
        <Accordion
          defaultIndex={[0, 1]}
          collapsible
          multiple
          className="space-y-12"
        >
          <AccordionItem>
            <Trees heading="Primary" trees={primary} />
          </AccordionItem>
          <AccordionItem>
            <Trees heading="Others" trees={secondary} />
          </AccordionItem>
        </Accordion>
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-lg font-bold tracking-wider uppercase">Key:</h2>
          <dl>
            {keys.map((k) => (
              <div key={k.dt}>
                <dt className="inline">{k.dt} – </dt>
                <dd className="inline">{k.dd}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </article>
  );
}

Species.propTypes = {
  primary: PropTypes.any,
  secondary: PropTypes.any,
};

function FAQs({ data }) {
  return (
    <CopyWithFullHeightImage variant="WHITE" image={data.images[0]}>
      <SanityBlockContent blocks={data._rawText} className="prose" />
      {data.callToAction ? (
        <CallToAction callToAction={data.callToAction} />
      ) : null}
    </CopyWithFullHeightImage>
  );
}

FAQs.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  {
    sanityHabitatPage {
      heroImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
        altText
      }
      koalaHabitat {
        _rawText
        callToAction {
          ... on SanityFileCta {
            _key
            _type
            file {
              asset {
                url
              }
            }
            text
          }
          ... on SanityLinkCta {
            _key
            _type
            link
            text
          }
          ... on SanityMembershipFormCta {
            _key
            _type
            membershipForm {
              membershipForm {
                asset {
                  url
                }
              }
            }
            text
          }
          ... on SanityPageCta {
            _key
            _type
            route
            text
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          altText
          hotspot {
            height
            width
            x
            y
          }
        }
      }
      primarySpecies {
        _key
        key
        value
      }
      secondarySpecies {
        _key
        key
        value
      }
      faq {
        _rawText
        callToAction {
          ... on SanityFileCta {
            _key
            _type
            file {
              asset {
                url
              }
            }
            text
          }
          ... on SanityLinkCta {
            _key
            _type
            link
            text
          }
          ... on SanityMembershipFormCta {
            _key
            _type
            membershipForm {
              membershipForm {
                asset {
                  url
                }
              }
            }
            text
          }
          ... on SanityPageCta {
            _key
            _type
            route
            text
          }
        }
        images {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
          altText
          hotspot {
            height
            width
            x
            y
          }
        }
      }
    }
  }
`;

export default HabitatPage;
